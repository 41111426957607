<template>
  <div>
    <img :src="headerBg" class="header" />
    <div style="width:1200px;margin:0 auto 50px auto;background-color: #fff;">
      <div class="title">AI商标注册成功率评估</div>
      <div style="display:flex;">
        <img :src="banner1" style="width:50%;">
        <div>
          <div style="font-size:20px;color:#218CF5;margin:150px 0 30px 0;">AI评估更靠谱，让商标注册成功率提升80</div>
          <Field label-width="100px" v-model.trim="brandName" label="商 标 名 称" placeholder="请输入要评估的商标名称" @input="checkWords"
                 @change="check('brandName')"/>
          <Field style="width:100%;" label-width="100px" v-model.trim="serviceName" label="商品或服务名称"
                 placeholder="请输入商品或服务，比如面包" readonly @click="goSearch"/>
          <div class="button" @click="go">点击免费试用</div>
        </div>
      </div>
      <div class="title">公司简介</div>
      <div style="display:flex;">
        <div style="padding:100px 100px;">
          知昇（杭州）人工智能科技有限公司，以大数据、AI算法驱动，致力于研发以提升商标注册成功率为市场入口的AI知识产权SaaS服务系统，将对企业的知产服务标准化、线上化、品质化、透明化，降低企业知识产权保护的时间成本和确权维权成本。
        </div>
        <img :src="banner2" style="width:40%;">
      </div>
    </div>
    <BtmMenu></BtmMenu>
  </div>
</template>

<script>
import {Field, Toast} from 'vant';
import headerBg from '../../assets/yz-pc/yz-pc-header.png'
import banner1 from '../../assets/yz-pc/banner1.png'
import banner2 from '../../assets/yz-pc/banner2.png'
import {forbid, insertClickLog, signLogin} from "@/api/api";
import {debounce} from "lodash";
import BtmMenu from "@/views/yz-pc/btm-menu";
export default {
  name: "index",
  data(){
    return {
      headerBg,banner1,banner2,
      companyName: '',
      brandName: '',
      serviceName: ''
    }
  },
  methods: {
    async go() {
      if (this.brandName && this.serviceName) {
        if(!this.check('brandName')) return false
        if(this.brandName){
          let result = await this.forbid()
          if(!result) return false
        }
        this.$router.push(`/yql/recommend?brandName=${this.brandName}&serviceName=${this.serviceName}`)
      }else if(!this.brandName){
        Toast('商标名称不能为空')
        return false
      }else if(!this.serviceName){
        Toast('商品或服务名称不能为空')
        return false
      }
    },
    goSearch() {
      this.$router.push(`/yql/search?value=${this.serviceName}`)
    },
    async signLogin() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
      });
      let {userMobile, accessKey, userNickname, sign} = this.$route.query;
      if (!sign) return false;
      let res = await signLogin({
        userMobile, accessKey, userNickname, sign: decodeURIComponent(sign)
      })
      if (res.code === 200) {
        this.$store.commit('setUserInfo', res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        console.log(this.$store.state.userInfo);
        //记录日志
        await this.insertClickLog(res.data)
        Toast.clear();
      }else{
        this.$router.go(-1)
      }
      console.log(res);
    },
    async insertClickLog({userCode, userName}) {
      let res = await insertClickLog({
        userCode,
        userName,
        channels: 'yql',
        urlName: 'FBW静默登录'
      })
    },
    check(key) {
      const regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im,
          regNum = /\D/g
      if(!regNum.test(this[key])){
        Toast("不能为纯数字");
        return false;
      }
      if (regEn.test(this[key]) || regCn.test(this[key])) {
        Toast("不能包含特殊字符");
        return false;
      }
      return true
    },
    async forbid() {
      if(!this.brandName) return false
      let res = await forbid({
        text: this.brandName
      })
      if (res.code === 200 && res.data.forbid === 'True') {
        Toast(res.data.reason)
        return false
      }
      return true
    },
    checkWords: debounce(function () {
      this.forbid()
    }, 500)
  },
  mounted() {
    this.$store.commit('setOrigin',this.$route.meta.origin)
  },
  activated() {
    if (this.$route.query.search || this.$route.query.search === '') {
      this.serviceName = this.$route.query.search
    }
  },
  components:{Field,BtmMenu}
}
</script>

<style scoped>
.header{
  width:100%;
}
.button{
  background: linear-gradient(180deg, #31B2FC 0%, #1C81F3 100%);
  border-radius: 4px;
  color:#fff;
  text-align:center;
  height:40px;
  line-height:40px;
  margin-top:50px;
}
.title{
  background-image: url(../../assets/yz-pc/title-bg.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  width:500px;margin:0 auto;
  padding:20px;
  text-align: center;
  height:40px;
  line-height:42px;
  background-position:center center;
}
</style>