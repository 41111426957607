<template>
  <div class="btm-menu">
    <span>
      顾 问：倪闰贵153-8106-5862
    </span>
    <span style="margin:0 30px;">
      服务时间：工作日的9:00-18:00
    </span>
    <span class="button" @click="onTelUs">联系顾问</span>
  </div>
</template>

<script>
import {Toast} from "vant";
import {putContactConsult} from "@/api/api";

export default {
  name: "btm-menu",
  methods:{
    async onTelUs() {
      let msg = '我们的顾问会及时联系您';
      if (this.timer > 1) return Toast(msg);
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      try {
        let userCode = this.$store.state.userInfo.userCode;
        let res = await putContactConsult({
          userCode,empCode:'EMP20211209133502284017'
        });
        if (res.code == 200) {
          Toast(msg);
          this.timer += 1;
        } else {
          this.timer = 1;
        }
      } catch (e) {
        //TODO handle the exception
      }
    }
  }
}
</script>

<style scoped>
.btm-menu{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  height:50px;
  line-height:50px;
  background-color: #fff;
  text-align: right;
}
.button{
  background: #F96006;
  border-radius: 2px;
  color:#fff;
  text-align: center;
  padding:10px 20px;
}

</style>